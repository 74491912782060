import React from 'react';
import { Message, Divider, Segment } from 'semantic-ui-react';
import paths from '../../../consts/paths';

const SignInPageMessage = () => (
  <>
    <Message size="big">
      <Message.Content>
        <strong>Backstage</strong>
        {' '}
        is where you can manage your account
      </Message.Content>
    </Message>
    <Message size="large">
      <Message.Content>
        <Segment textAlign="center">
          <h3>New to Backstage?</h3>
          <p>
            If you are
            {' '}
            <em>enrolled</em>
            {' '}
            with us, have a
            {' '}
            <em>reserved</em>
            {' '}
            place
            or are on our
            {' '}
            <em>waiting list</em>
            :
          </p>
          <a href={paths.SIGN_UP} className="ui blue button large ">
            Create an Account
          </a>
          <Divider hidden />
          <h3>New to Rocksteady?</h3>
          <p>If this is the first time you’ve contacted us:</p>
          <a
            className="ui blue button large "
            href="https://booking.rocksteadymusicschool.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Enrol your Child
          </a>
          <Divider hidden />
          <h3>Accepting a Reserved Place?</h3>
          <p>
            If you already have a Backstage account,
            {' '}
            <strong>Sign In</strong>
            {' '}
            and complete the things that need your attention shown on the screen.
            <br />
            <br />
            If you are new to Backstage, please create an account first, using the
            {' '}
            <strong>&lsquo;Create an Account&lsquo;</strong>
            {' '}
            button above.
            <br />
            Then,
            {' '}
            <strong>Sign In</strong>
            {' '}
            and complete the things that need your attention shown on the screen.
          </p>
        </Segment>
      </Message.Content>
    </Message>
  </>
);

export default SignInPageMessage;
