import React, { Component } from 'react';
import {
  Card,
  List,
  Button,
  Popup,
  Image,
  Grid,
  Divider
} from 'semantic-ui-react';
import MainHeading from '../MainHeading/MainHeading';
import SecondaryHeading from '../SecondaryHeading/SecondaryHeading';
import DealCard from './DealCard';
import WindowUtils from '../../utils/WindowUtils';

// Styles
import './InstrumentDealsRentals.scss';

// Images
import BuyBass from './images/buy-bass.jpg';
import BuyDrums from './images/buy-drums.jpg';
import BuyGuitar from './images/buy-guitar.jpg';
import BuyKeyboard from './images/buy-keyboard.jpg';
import BuyVocals from './images/buy-vocals.jpg';
import RentGuitar from './images/Rent-Normans-Guitar.jpg';
import RentKeyboard from './images/Rent-Normans-Keyboard.jpg';
import Banner from './images/band-instrument-deals.jpg';

class InstrumentDealsRentals extends Component {
  componentDidMount() {
    WindowUtils.scrollToTop();
  }

  copyToClipboard = btnClass => {
    // eslint-disable-next-line no-undef
    const data = [ new ClipboardItem({ 'text/plain': new Blob(['NRRS20'], { type: 'text/plain' }) }) ];
    navigator.clipboard.write(data).then(() => {
      const copyBtn = document.querySelector(`.${btnClass}`);
      const initialCopyBtnText = copyBtn.innerHTML;
      copyBtn.innerText = 'Copied!';
      copyBtn.blur();
      setTimeout(() => {
        copyBtn.innerHTML = initialCopyBtnText;
      }, 2e3);
    });
  };

  render() {
    return (
      <div className="instrument-deals">

        {/* Heading */}
        <MainHeading
          heading="Rock Star Ready Equipment"
          subHeading="Exclusive Offers and Bundle Deals"
        />

        {/* Divider */}
        <div style={{ height: '10px', backgroundColor: '#0072ce' }} />
        <div style={{ height: '5px', backgroundColor: '#00e7ef' }} />

        {/* Description */}
        <h2>
          Looking to hire or buy an instrument so your child can practice at
          home? We can help
        </h2>
        <p>
          In-school we bring everything your child needs for their band lessons,
          and you can join in Rocksteady lessons online with or without an
          instrument. However, if you do want an instrument for your child to
          practise and play at home, but don’t want to spend hours researching
          what to buy or hire, we’ve teamed up with online music retailer Normans
          to bring you some exclusive instrument packages for current Rocksteady
          subscribers.
        </p>
        <p>Rocksteady is not affiliated with Normans.</p>

        <Image src={Banner} />

        {/* Heading for Buying an Instrument */}
        <SecondaryHeading heading="What's available to buy?" />

        {/* Instrument cards for buying instruments */}
        <Card.Group stackable itemsPerRow={2}>
          <DealCard
            img={BuyDrums}
            heading="Drums Starter Pack | £244.99"
            description="The Axus AXK4 Digital Drum Kit is an ideal electronic drum kit for beginners. It is fully functional and has everything you need to get started at home - comes with drum sticks, headphones and drum Stool."
            buttonPath="https://ti.to/rocksteady-music-school/instruments/with/swdasmym8-w"
            buttonText="View Deal"
          />

          <DealCard
            img={BuyGuitar}
            heading="3/4 Guitar Starter Pack | £119.99"
            description="This 3rd Avenue ¾ Electric Guitar is a great option as a first guitar for younger learners. It is a 3/4 size instrument, so suitable for most 4-11 years olds. This pack has everything you need to get started at home and comes with 10W amplifier, jack cable, guitar stand, capo, strap, carry bag, spare strings and picks."
            buttonPath="https://ti.to/rocksteady-music-school/instruments/with/zpvlexxgn94"
            buttonText="View Deal"
          />

          <DealCard
            img={BuyBass}
            heading="3/4 Bass Starter Pack | £144.99"
            description="This 3rd Avenue Bass Guitar is especially designed for children. It has a slightly shorter neck length, so suitable for most young learners aged between 4-11 years who may not quite have the full reach of a full-size bass. This pack has everything you need to get started at home and comes with 15W amplifier, jack cable, guitar stand, strap, carry bag and spare strings."
            buttonPath="https://ti.to/rocksteady-music-school/instruments/with/u5chdiaeptm"
            buttonText="View Deal"
          />

          <DealCard
            img={BuyKeyboard}
            heading="Keyboard Starter Pack | £144.99"
            description="The AXP2 Electronic Keyboard by Axus features 61 touch sensitive keys. This pack has everything you need to get started at home - keyboard, stand, stool and headphones."
            buttonPath="https://ti.to/rocksteady-music-school/instruments/with/gatxpbb937a"
            buttonText="View Deal"
          />
        </Card.Group>

        <DealCard
          img={BuyVocals}
          heading="Vocals Starter Pack | £144.99"
          description="The Stagg Vocals pack is ideal for home use. It has Bluetooth connectivity for backing tracks and a rechargeable 12v battery. Controls on the back allow you to adjust the microphone volume, AUX volume and turn Bluetooth connection on or off. This pack has everything you need to get started at home - PA speaker, microphone, XLR cable, mic stand and aux cable for phone or tablet."
          buttonPath="https://ti.to/rocksteady-music-school/instruments/with/bk3hkawfqq8"
          buttonText="View Deal"
          fullwidth
        />
        <br />

        <Divider />

        <h3 className="how-to-buy">How to buy</h3>
        <p>
          To order click view deal to visit our Ti.To site and follow the instructions.
          Normans will be in touch within one working day and your instruments will usually
          be delivered in a few days.
        </p>

        {/* Heading for Renting an Instrument */}
        <SecondaryHeading heading="What's available to hire?" />

        {/* Description for Renting an Instrument */}
        <p>
          Not ready to splash out on your own kit? Don’t worry there are options
          to hire for a small monthly fee. (Minimum hire period is three
          months).
        </p>

        <Grid stackable>
          <Grid.Row columns={2}>

            <Grid.Column>
              <h3>What happens after three months?</h3>
              You can choose to:
              <List bulleted>
                <List.Item>Give the instrument back.</List.Item>
                <List.Item>
                  Carry on paying the monthly hire price for the remaining nine
                  months. After hiring for 12 months in total the instrument
                  will be yours forever.
                </List.Item>
                <List.Item>
                  Or pay a settlement price and own the instrument straight
                  away. (Cheaper than hiring for 12 months)
                </List.Item>
              </List>
            </Grid.Column>

            <Grid.Column>
              <h3>How to hire</h3>
              <List bulleted>
                <List.Item>
                  Complete the form on Normans Musical Instruments hire page.
                </List.Item>
                <List.Item>
                  Enter this teacher code
                  {' '}
                  <Popup
                    trigger={<strong className="rs-dark-blue">NRRS20</strong>}
                    hoverable
                    className="copy-popup"
                  >
                    <Button
                      className="normans-code-inline"
                      onClick={() => this.copyToClipboard('normans-code-inline')}
                    >
                      Copy code
                    </Button>
                  </Popup>
                </List.Item>

                <List.Item>Fill out the direct debit form.</List.Item>
                <List.Item>Normans will confirm your request.</List.Item>
                <List.Item>
                  Your instrument will be sent out as soon as possible.
                </List.Item>
              </List>
            </Grid.Column>

          </Grid.Row>
        </Grid>
        <br />
        <br />
        <p>
          Currently you can choose to hire:
        </p>
        {/* Instrument cards for hiring instruments */}

        <Card.Group stackable itemsPerRow={2}>
          <DealCard
            img={RentGuitar}
            heading="¾ size or full-size electric guitar bundle"
            description="– includes guitar, amplifier, gig bag, spare strings, capo, guitar strap, digital tuner, plectrums and cables."
            buttonPath="https://www.normans.co.uk/topic/rent-an-electric-guitar"
            buttonText="Hire Now"
            copyButton={this.copyToClipboard}
            instrument="guitar"
          />

          <DealCard
            img={RentKeyboard}
            heading="Digital keyboard bundle"
            description="– includes keyboards, music stand, mains adaptor, keyboard stand, bench and headphones."
            buttonPath="https://www.normans.co.uk/topic/rent-a-keyboard"
            buttonText="Hire Now"
            copyButton={this.copyToClipboard}
            instrument="keyboard"
          />
        </Card.Group>

        <br />
        <br />
      </div>
    );
  }
}

export default InstrumentDealsRentals;
