const paths = Object.freeze({
  ROOT: '/',
  MY_DETAILS: '/details',
  DIRECT_DEBITS: '/direct-debits',
  SETTINGS: '/settings',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  FAQ: '/faq',
  ENROL: '/enrol',
  ENROL_RESERVED_KID: '/enrol-reserved-child',
  INSTRUMENT_CHANGE: '/instrument-change',
  AVAILABLE_INSTRUMENT_CHANGES: '/available-instrument-changes',
  INSTRUMENT_DEALS_RENTALS: '/instrument-deals-rentals',
  KIDS_APP: '/rocksteady-play',
  ROUND_UP: kid_id => `/round-up/${kid_id}`,
  get KIDS_APP_SUBSCRIBE() {
    return `${this.KIDS_APP}/subscribe`;
  },
  KIDS_APP_IOS: 'https://apps.apple.com/app/rocksteady-play/id6446947466',
  KIDS_APP_ANDROID:
    'https://play.google.com/store/apps/details?id=com.rocksteadymusicschool.rocksteadyworld',
  KIDS_APP_FIRE: 'https://www.amazon.co.uk/gp/product/B0C6MLFTT7',
  MERCHANDISE_SITE: 'https://rocksteady-music-school.teemill.com',
  // MY KID'S JOURNEY
  TIMELINE: kid_id => `/timeline/${kid_id}`,
  ROUND_UP_DIRECT: `/round-up/:kid_id`,

  // LOGIN
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  SIGN_OUT: '/sign-out',
  UNLOCK_ACCOUNT: '/unlock-account',
  SCHOOLS_SIGN_IN: '/schools/sign-in',
  SCHOOLS_SIGN_OUT: '/schools/sign-out',
  SCHOOLS_FORGOTTEN_PASSWORD: '/schools/forgotten-password',
  SCHOOLS_SET_PASSWORD: '/schools/set-password',
  SCHOOLS_ROOT: '/schools',
  SET_PASSWORD: '/set-password',
  FORGOTTEN_PASSWORD: '/forgotten-password',
  FEEDBACK: '/feedback',
  KID: kid_id => `/kid/${kid_id}`,
  ENROLMENT_STATUS: kid_id => `/kid/${kid_id}/enrolment-status`,
  SONG: song_id => `/song/${song_id}`,
  TIMETABLE: kid_id => `/kid/${kid_id}/timetable`,
  STAFF_PROFILE: band_leader_id =>
    `/band_leader/${band_leader_id}/staff_profile`,
  CANCELLATION_FORM: kid_id => `/kid/${kid_id}/cancellation_form`,
  COOKIE_POLICY: '/cookie-policy',
  WWW_TERMS_CONDITIONS:
    'https://www.rocksteadymusicschool.com/terms-and-conditions-parents'
});

export default paths;
