import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { Icon, Image, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import paths from '../../consts/paths';
import personAvatar from '../../images/portal/person_avatar.svg';

const KidMenuItem = ({ kid, isWelcomePage }) => {
  const [isOpen, setIsOpen] = useState(false)

  const unseenLabel = (count) => {
    if (count > 0) {
      return <Label circular className="circular-label">{count}</Label>
    }
    return null;
  }

  const timelineLink = () => {
    const unseenCount = kid.attributes.timeline_entries.data.filter((entry) => {
      return entry.attributes.date_seen === null;
    }).length;

    return (
      <div className="sub-menu-link-wrapper">
        <Link
          style={{
            borderLeft: '4px solid #0072CE',
          }}
          to={paths.TIMELINE(kid.id)}
        >
          <span>
            {kid.attributes.first_name}
            &apos;s Timeline
          </span>
          {unseenLabel(unseenCount)}
        </Link>
      </div>
    );
  }

  const isEnrolled = () => {
    const { status } = kid.attributes;
    return status.indexOf('enrolled') > -1;
  };

  const isActive = () => {
    if (isEnrolled()) {
      return true;
    }
    const { attributes } = kid;
    const { status } = attributes;
    return status === 'waiting_list';
  };

  const isDeactivated = () => {
    if (isActive()) { return false };

    const { attributes } = kid;
    const { status } = attributes;

    return !['reserved', 'reserved_during_enrolment'].includes(status);
  }

  const bandLeaderId = () => {
    if (kid.attributes.timetable_bl && kid.attributes.timetable_bl.data) {
      return kid.attributes.timetable_bl.data.attributes.band_leader_id;
    }

    return null;
  }

  const timetableLink = () => {
    if (isEnrolled()) {
      return (
        <div className="sub-menu-link-wrapper">
          <Link
            style={{
              borderLeft: '4px solid #00F0B2',
            }}
            to={paths.TIMETABLE(kid.id)}
          >
            <span>
              {kid.attributes.first_name}
              &apos;s Timetable
            </span>
          </Link>
        </div>
      )
    }
    return null;
  }

  const bandLeaderLink = () => {
    if (bandLeaderId()) {
      return (
        <div className="sub-menu-link-wrapper">
          <Link
            style={{
              borderLeft: '4px solid #F3D69D',
            }}
            to={paths.STAFF_PROFILE(bandLeaderId())}
          >
            <span>
              {kid.attributes.first_name}
              &apos;s Band Leader
            </span>
          </Link>
        </div>
          )
        }

    return null;
  }

  const editLink = () => {
    if (isActive()) {
      return (
        <div className="sub-menu-link-wrapper">
          <Link
            style={{
              borderLeft: '4px solid #E22A3F',
            }}
            to={paths.KID(kid.id)}
          >
            <span>
              Make Changes
            </span>
          </Link>
        </div>
      )
    }
    return null;
  }

  const instrumentLink = () => (
    <div className="sub-menu-link-wrapper">
      <Link
        style={{
          borderLeft: '4px solid #60BFF0',
        }}
        to={paths.INSTRUMENT_DEALS_RENTALS}
      >
        <span>
          Buy/Hire Instrument
        </span>
      </Link>
    </div>
  )

  const rejoinLink = () => {
    if (isDeactivated()) {
      return (
        <div className="sub-menu-link-wrapper">
          <Link
            style={{
              borderLeft: '4px solid #21BA45',
            }}
            to={{ pathname: paths.ENROL, state: { kid } }}
          >
            <span>
              Enrol
            </span>
          </Link>
        </div>
      );
    }
    return null
  }

  const subMenuButtonClass = isWelcomePage ? 'sub-menu-item-welcome' : 'sub-menu-item';

  const subMenuWrapperClass = isWelcomePage ? 'sub-menu-welcome-wrapper' : null;

  const subMenu = () => (
    <div className={subMenuWrapperClass} style={{ backgroundColor: '#EDF2F7' }}>
      <button
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          width: '100%',
        }}
        className={subMenuButtonClass}
        onClick={() => setIsOpen(false)}
        type="button"
      >
        <span title={kid.attributes.name}>
          <Image src={personAvatar} className="avatar" />
          {kid.attributes.name.substring(0, 22)}
        </span>
        <Icon
          name="chevron up"
          style={{
            float: 'right',
            margin: 0,
            cursor: 'pointer'
          }}
        />
      </button>
      <div className="sub-menu-links">
        {timelineLink()}
        {timetableLink()}
        {rejoinLink()}
        {bandLeaderLink()}
        {editLink()}
        {instrumentLink()}
      </div>
    </div>
  )

  const closedMenuClass = isWelcomePage ? 'sub-menu-item-welcome' : 'item';

  const closedMenu = () => (
    <button
      className={closedMenuClass}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        cursor: 'pointer',
        marginLeft: 0,
      }}
      type="button"
      onClick={() => setIsOpen(true)}
    >
      <span title={kid.attributes.name}>
        <Image src={personAvatar} className="avatar" />
        {kid.attributes.name.substring(0, 22)}
      </span>
      <Icon
        name="chevron down"
        style={{
          float: 'right',
          margin: 0,
        }}
      />
    </button>
  )

  return isOpen ? subMenu() : closedMenu();
}

KidMenuItem.propTypes = {
  kid: PropTypes.object.isRequired,
  isWelcomePage: PropTypes.bool,
  bandLeaderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

KidMenuItem.defaultProps = {
  isWelcomePage: false,
}

export default KidMenuItem;
