import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WindowUtils from '../../utils/WindowUtils';
import VideoModal from '../VideoModal/VideoModal';
import TimelineEntry from './TimelineEntry/TimelineEntry';
import TimelineAPI from '../../api/TimelineAPI';
import ToastMessage from '../ToastMessage/ToastMessage';

class Timeline extends Component {
  
  static propTypes = {
    kid: PropTypes.object.isRequired,
    fetchGrownUpRelationships: PropTypes.func.isRequired,
  }
  
  constructor(props) {
    super(props);
    this.state = {
      videoModalOpen: false,
      vimeoID: null,
      videoTitle: null
    };
  }
  
  componentDidMount() {
    WindowUtils.scrollToTop();
    const { kid, fetchGrownUpRelationships } = this.props;
    const params = { kid_id: kid.id }
    const unseenCount = kid.attributes.timeline_entries.data.filter((entry) => {
      return entry.attributes.date_seen === null;
    }).length;
    if (unseenCount > 0) {
      TimelineAPI.markAsSeen(params).then(() => {
        fetchGrownUpRelationships();
      });
    }
  }

  openVideoModal = (entry) => {
    this.setState({ videoModalOpen: true, vimeoID: entry.attributes.vimeo_id, videoTitle: entry.attributes.video_title })
  };

  closeVideoModal = () => this.setState({ videoModalOpen: false, vimeoID: null });

  videoModal = () => {
    const { videoModalOpen, vimeoID, videoTitle } = this.state;

    if (videoModalOpen) {
      return (
        <VideoModal videoId={vimeoID} onClose={this.closeVideoModal} headerText={videoTitle} />
      )
    }
    return null;
  }

  setToastType = (result) => this.setState({ displayToastMessage: true, toastSuccess: result})

  displayToastMessage = () => {
    const { displayToastMessage, toastSuccess } = this.state;
    if (!displayToastMessage) { return null }

    if (toastSuccess) {
      return <ToastMessage title="Thanks for reporting" body="A member of staff will review your report shortly." />
    } 
      return (
        <ToastMessage
          title="Something went wrong"
          body="Please try again in a moment or contact us by phone on: 03301130330"
          iconName="exclamation"
          iconColor="#942d29"
          backgroundColor="#ff8985"
          textColor="#521715"
        />
      )
  }

  timelineEntry = entry => {
    const borderColor = entry.attributes.color;
    const onThumbnailClick = () => this.openVideoModal(entry);
    const { kid, fetchGrownUpRelationships } = this.props;

    return (
      <TimelineEntry
        borderColor={borderColor}
        onThumbnailClick={onThumbnailClick}
        setToastType={this.setToastType}
        entry={entry}
        kid={kid}
        key={entry.id}
        fetchGrownUpRelationships={fetchGrownUpRelationships}
      />
    )
  }
    
    render() {
    const { kid } = this.props;

    if (kid) {
      return (
        <div className="page-container">
          <h2>
            {kid.attributes.first_name}
            &apos;s Timeline
          </h2>
          {this.displayToastMessage()}
          {kid.attributes.timeline_entries.data.map(entry => this.timelineEntry(entry))}
          {this.videoModal()}
        </div>
      )
    }
    
    return null;
  }
}

export default Timeline;
