import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Accordion, Icon, Image, Menu } from 'semantic-ui-react';
import MediaQuery from 'react-responsive';
import logo from '../../rocksteady-logo-blue.svg';
import paths from '../../consts/paths';
import menu from '../../consts/menu';
import shirt from '../../images/portal/shirt-icon.svg';

class SiteHeader extends Component {
  customIcons = { shirt };

  static propTypes = {
    signedIn: PropTypes.bool,
  }

  static defaultProps = {
    signedIn: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: -1
    };
  }

  trimmedPath = () => {
    const path = window.location.pathname
    let safePath = path
    const lastChar = path.slice(-1)

    if (lastChar === "/" && path.length > 1) {
      safePath = path.slice(0, -1); 
    } 

    return safePath;
  }


  closeMenu = () => {
    this.setState({
      activeIndex: -1
    });
  };

  menuLinkIcon = menuItem => (
    menuItem.customIconName
      ? <Image as="img" className="custom-icon" src={this.customIcons[menuItem.customIconName]} ui inline />
      : <Icon name={menuItem.iconName} />
  )

  menuLink = menuItem => {
    return (
      <Link
        key={menuItem.text}
        className="item"
        to={{ pathname: menuItem.path }}
        target={menuItem.target || '_self'}
        rel={menuItem.target === '_blank' ? 'noopener noreferrer' : ''}
        onClick={this.closeMenu}
      >
        {this.menuLinkIcon(menuItem)}
        {' '}
        {menuItem.text}
      </Link>
    )
  };

  mobileMenu = () => {
    const { activeIndex } = this.state;
    const menuItems = menu.MENU_ITEMS;

    if (activeIndex >= 0) {
      return (
        <Menu vertical fluid secondary className="mobile-menu">
          <div className='mobile-menu-shadow'>
            {menuItems.map(menuItem => this.menuLink(menuItem)) }
          </div>
        </Menu>
      )
    }
    
    return null;
  }

  handleBurgerClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  hamburger = () => {
    const { activeIndex } = this.state;
    const { signedIn } = this.props;

    if(signedIn) {
      return (
        <MediaQuery maxWidth={768}>
          <div className="hamburger-menu">
            <Accordion>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={this.handleBurgerClick}
                className="hamburger"
              >
                <Icon name="sidebar" />
              </Accordion.Title>
            </Accordion>
          </div>
        </MediaQuery>  
      )
    }

    return null;
  };

  signOut = () => {
    const {signedIn} = this.props;

    if (signedIn) {
      return (
        <Link to={paths.SIGN_OUT}>
          Sign out
        </Link>
      )
    }

    return null;
  }

  render() {
    return (
      <div className="site-header">
        <a href="/">
          <img src={logo} className="logo" alt="logo" />
        </a>
        <div className="utility-headstrip">
          <div className="header-title">
            <div className="title-links">
              <a target='_blank' rel='noopener noreferrer' href="https://www.rocksteadymusicschool.com">
                Rocksteadymusicschool.com
              </a>
              {this.signOut()}
            </div>
            {this.hamburger()}
            <a href="/">
              <h4>Backstage</h4>
            </a>
          </div>
        </div>
        {this.mobileMenu()}
      </div>
    )
  };
};  

export default SiteHeader;
