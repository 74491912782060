import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import './index.scss';
import BugsnagClient from './utils/BugsnagClient';
import AppView from './views/AppView';
import SomethingWentWrong from './components/SomethingWentWrong/SomethingWentWrong';
import CookiesUtils from './utils/CookiesUtils';

import './styles/fonts/5730950/227de6ba-fef9-435d-8693-a53a76aa1add.eot';
import './styles/fonts/5730950/dae937ec-159f-4710-9b2d-9470e24807ba.woff2';
import './styles/fonts/5730950/c8856bf2-4d23-4e30-9fb1-eede15b787b6.woff';
import './styles/fonts/5730950/376f433f-1486-4168-932f-06352c8291f8.ttf';

const ErrorBoundary = BugsnagClient.getPlugin('react');

// We don't use GA anymore, make sure we purge any leftover cookies
// when the user loads the page.
CookiesUtils.removeExistingGACookies();

ReactDOM.render(
  <ErrorBoundary FallbackComponent={SomethingWentWrong}>
    <BrowserRouter>
      <AppView />
    </BrowserRouter>
  </ErrorBoundary>,
  document.getElementById('root'),
);
