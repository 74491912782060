import paths from './paths';

const menu = Object.freeze({
  MENU_ITEMS: [{ 
    path: paths.ROOT,
    iconName: 'child',
    text: 'Home'
  },
  {
    path: paths.MY_DETAILS,
    iconName: 'id card',
    text: 'Your Details',
    notificationKey: 'user'
  },
  {
    path: paths.ENROL,
    iconName: 'user plus',
    text: 'Register New Child'
  },
  {
    path: paths.INSTRUMENT_DEALS_RENTALS,
    iconName: 'microphone',
    text: 'Instrument Deals'
  },
  {
    path: paths.MERCHANDISE_SITE,
    customIconName: 'shirt',
    text: 'Merchandise',
    target: '_blank',
  },
  {
    path: paths.SETTINGS,
    iconName: 'settings',
    text: 'Settings'
  },
  {
    path: paths.TERMS_AND_CONDITIONS,
    iconName: 'file alternate outline',
    text: 'Terms And Conditions'
  },
  {
    path: paths.FAQ,
    iconName: 'question circle outline',
    text: 'FAQs'
  },
  {
    path: paths.FEEDBACK,
    iconName: 'comment',
    text: 'Feedback'
  },
  {
    path: paths.SIGN_OUT,
    iconName: 'sign out',
    text: 'Sign Out'
  }]
});

export default menu;
