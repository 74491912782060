import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';
import UserAPI from '../../api/UserAPI';
import GetPassword from '../GetPassword/GetPassword';
import SetPassword from '../SetPassword/SetPassword';
import SignInForm from '../SignInForm/SignInForm';
import CookiePolicy from '../CookiePolicy';
import JWTManager from '../../utils/JWTManager';
import WindowUtils from '../../utils/WindowUtils';
import SiteHeader from '../SiteHeader';
import SiteFooter from '../SiteFooter';
import paths from '../../consts/paths';
import UnlockAccount from '../UnlockAccount/UnlockAccount';

class AdminApp extends Component {
  constructor(props) {
    super(props);
    this.state = this.initState();
  }

  initState = () => ({
    authorizationToken: JWTManager.getToken() || null,
  });

  setJwtToken = (token) => {
    // console.log(token);
    JWTManager.setToken(token).then(
      this.setState({
        authorizationToken: token,
      }),
    );
  };

  signInForm = () => {
    return <SignInForm onSigningIn={this.setJwtToken} signedIn={this.hasAuthenticated()} />
  };

  setPassword = () => (
    <SetPassword onSigningIn={this.setJwtToken} signedIn={this.hasAuthenticated()} />
  );

  getPassword = forgotPassword => {
    if (forgotPassword) {
      return <GetPassword forgottenPassword />
    }

    return <GetPassword />
  }

  cookiePolicy = () => (
    <CookiePolicy />
  )

  unlockAccount = () => (
    <UnlockAccount />
  )

  // Attempting to setState explicitly here produces console warnings, but we don't need it,
  // because the state is reset automagically,
  signOut = () => {
    UserAPI.signOut().then(() => {
      JWTManager.removeToken();
      localStorage.clear();
      WindowUtils.replaceLocation(`${paths.SIGN_IN}?signedOut=true`);
    });
  };

  hasAuthenticated = () => {
    const { authorizationToken } = this.state;
    return authorizationToken !== null;
  };

  adminPage = (path) => {
    switch(path) {
      case paths.SIGN_IN:
        return this.signInForm()
      case paths.SIGN_UP:
        return this.getPassword(false);
      case paths.SIGN_OUT:
        this.signOut()
        return this.signInForm();
      case paths.FORGOTTEN_PASSWORD:
        return this.getPassword(true);
      case paths.SET_PASSWORD:
        return this.setPassword();
      case paths.COOKIE_POLICY:
        return this.cookiePolicy();
      case paths.UNLOCK_ACCOUNT:
        return this.unlockAccount();
      default:
        return null;
    }
  }

  pageContents = () => {
    return (
      this.adminPage(window.location.pathname)
    )
  }

  render() {
    return (
      <Container className="site-container admin-app">
        <SiteHeader />
        {this.pageContents()}
        <SiteFooter />
      </Container>
    )
  }
}

export default AdminApp
