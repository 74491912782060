import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NPSForm from './NpsForm';
import PageHeading from '../PageHeading/PageHeading';
import WindowUtils from '../../utils/WindowUtils';
import StringUtils from '../../utils/StringUtils';

class NPS extends Component {
  static propTypes = {
    grownUpId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    grownUpName: PropTypes.string.isRequired,
    grownUpAttributes: PropTypes.object.isRequired,

  };

  constructor(props) {
    super(props);
    this.state = {
      showNPSThanksMessage: false,
      preselectedScore: this.scoreFromQueryParams()
    };
  }

  scoreFromQueryParams = () => {
    const urlParams = StringUtils.parseQueryString(WindowUtils.anyParams());
    const { score } = urlParams;
    return score ? Number(score) : null
  }

  setShowNPSThanksMessage = value => {
    this.setState({ showNPSThanksMessage: value });
  };

  render() {
    const { grownUpId, grownUpName, grownUpAttributes } = this.props;
    const { showNPSThanksMessage, preselectedScore } = this.state;
    return (
      <div className="page-container">
        <PageHeading heading="Feedback" />
        <NPSForm
          grownUpId={grownUpId}
          grownUpName={grownUpName}
          grownUpAttributes={grownUpAttributes}
          setGrownUp={() => {}}
          showNPSThanksMessage={showNPSThanksMessage}
          showForm={!showNPSThanksMessage}
          setShowNPSThanksMessage={this.setShowNPSThanksMessage}
          preselectedScore={preselectedScore}
        />
      </div>
    )
  }
}
export default NPS;
