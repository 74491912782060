import React from 'react';
import moment from 'moment';

// Styles
import './SiteFooter.css';
import contacts from '../../consts/contacts';
import sixteenYearsLogo from '../../images/portal/Rocksteady16YearsLogo.png';
import whatsappLogo from '../../images/portal/whatsapp.svg';
import instagramLogo from '../../images/portal/instagram-small.svg';
import twitterLogo from '../../images/portal/twitter-small.svg';
import facebookLogo from '../../images/portal/facebook-small.svg';
import merchBanner from '../../images/portal/Merch-banner.png';

const thisYear = moment().year();

const SiteFooter = () => (
  <div id="site-footer-wrapper">
    <div id="banner-container">
      <div id="banner">
        <a 
          id="site-banner"
          href="https://rocksteady-music-school.teemill.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={merchBanner} alt="Merch banner" />
          <span id="banner-text">
            Rocksteady branded merchandise
            <br />
            now available!
          </span>
        </a>
      </div>
    </div>
    <div id="site-footer">
      <div id="site-footer-left-column">
        <div className="terms">
          <a
            href="https://www.rocksteadymusicschool.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Notice
          </a>
          <a
            href="/cookie-policy"
            target="_self"
          >
            Cookie Policy
          </a>
          <a
            href="https://www.rocksteadymusicschool.com/terms-conditions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Website Terms &amp; Conditions
          </a>
          <a
            href="https://support.rocksteadymusicschool.com/hc/en-gb/requests/new"
            target="_blank"
            rel="noopener noreferrer"
          >
            Submit a Request
          </a>
        </div>
        <div className="social-media">
          <span>
            <a
              href={contacts.FACEBOOK_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebookLogo} alt="facebook" />
            </a>

            <a
              href={contacts.TWITTER_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitterLogo} alt="twitter" />
            </a>

            <a
              href={contacts.INSTAGRAM_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagramLogo} alt="instagram" />
            </a>

            <a
              href={contacts.WHATSAPP_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsappLogo} alt="whatsapp" />
            </a>
          </span>
        </div>
        <div className="copyright">
          <p>
            &copy;
            {' '}
            {thisYear}
            {' '}
            Rocksteady Music School Liphook, Hampshire. All Rights Reserved.
          </p>
        </div>
      </div>
      <div id="site-footer-right-column">
        <img src={sixteenYearsLogo} alt="Celebrating 16 years of Rocksteady" />
      </div>
    </div>
  </div>
);

export default SiteFooter;
